import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PopoverMenuItem from 'components/TrackAssignment/PopoverMenuItem';
import { featureToggle } from 'constants/index';
import { resetError } from 'stores/assignments/actions';
import { isNil } from 'ramda';
import { ActionsMenu, MenuContent } from './PopoverMenu.styles';

export default function PopoverMenu({
  homeworkID,
  openDeleteModal,
  setSelectedHomeworkID
}) {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  const dispatch = useDispatch();
  return (
    <ActionsMenu>
      <button type="button" className="more-options">
        ...
      </button>
      <MenuContent className="actions-dropdown-content">
        {featureToggle.popoverMenu && (
          <>
            <PopoverMenuItem icon="edit">
              <span style={{ marginLeft: '1rem' }}>
                {t(`track-assignment-assigned-me:main.edit`, 'Edit')}
              </span>
            </PopoverMenuItem>
          </>
        )}
        <PopoverMenuItem icon="print">
          <span
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              const sessionSecret = sessionStorage.getItem('secret');
              const url = isNil(sessionSecret)
                ? `${process.env.REACT_APP_DOMAIN}${
                    process.env.REACT_APP_SUBDOMAIN
                  }${
                    process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'
                  }print/${homeworkID}`
                : `${process.env.REACT_APP_DOMAIN}${
                    process.env.REACT_APP_SUBDOMAIN
                  }${
                    process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'
                  }print/${homeworkID}?secret=${sessionSecret}`;
              const win = window.open(url, '_blank');
              if (win != null) {
                win.focus();
              }
            }}
          >
            {t(`track-assignment-assigned-me:main.print`, 'Print')}
          </span>
        </PopoverMenuItem>
        <PopoverMenuItem icon="delete">
          <span
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              setSelectedHomeworkID(homeworkID);
              dispatch(resetError());
              openDeleteModal();
            }}
          >
            {t(`track-assignment-assigned-me:main.delete`, 'Delete')}
          </span>
        </PopoverMenuItem>
      </MenuContent>
    </ActionsMenu>
  );
}
