import React from 'react';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import Forbidden from 'pages/Forbidden';
import { useCookies } from 'react-cookie';
import BrowseContent from 'pages/BrowseContent/BrowseContent';

// Helper
const checkIfLoggedIn = (env, cookies) => {
  let isLoggedIn;
  if (env === 'dev') {
    if (!isNil(localStorage.getItem('access_token'))) {
      isLoggedIn = true;
    } else if (isNil(cookies.sp_access_token)) {
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
      localStorage.setItem('access_token', cookies.tp_access_token);
      localStorage.setItem('refresh_token', cookies.tp_refresh_token);
    }
  } // env = prod
  else if (!isNil(cookies.tp_access_token)) {
    isLoggedIn = true;
    localStorage.setItem('access_token', cookies.tp_access_token);
    localStorage.setItem('refresh_token', cookies.tp_refresh_token);
  } else if (!isNil(localStorage.getItem('access_token'))) {
    isLoggedIn = true;
  } else {
    isLoggedIn = false;
  }
  return isLoggedIn;
};

export default function AuthenticatedRoute({
  component: Component,
  forAdmin,
  ...rest
}) {
  const [cookies] = useCookies(['tp_access_token', 'tp_refresh_token']);
  const location = useLocation();
  const { isAdmin, isTrialExpired, isTrial } = useSelector(
    state => state.login
  );
  const env =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NETLIFY === 'true'
      ? 'dev'
      : 'prod';
  const isLoggedIn = checkIfLoggedIn(env, cookies);
  const v2Scope = `${process.env.REACT_APP_V2_MODULE_SCOPE}`
    .split(',')
    .map(s => s.trim());
  const loadPage = props => {
    const loginRoute =
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_NETLIFY === 'true'
        ? '/login'
        : '/members-login';
    if (isLoggedIn === false) {
      return (
        <Redirect
          push
          to={{ pathname: loginRoute, state: { from: props.location } }}
        />
      );
    }

    if (forAdmin && !isAdmin) {
      return <Forbidden />;
    }

    if (isTrialExpired && isTrial) {
      return <BrowseContent />;
    }
    if (
      v2Scope.some(scope => location.pathname.includes(scope)) &&
      env !== 'dev'
    ) {
      return window.location.replace(
        `${process.env.REACT_APP_V2_PUBLIC_URL}${location.pathname}`
      );
    }
    return <Component {...props} />; // eslint-disable-line
  };
  return (
    <Route
      {...rest} // eslint-disable-line
      render={loadPage}
    />
  );
}
