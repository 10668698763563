const fetchGlobalFeatureConfig = () => {
  return fetch(
    `${process.env.REACT_APP_CONTINGENCY_CONFIG_URL}?hash=${Date.now()}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .then(myJson => {
      return myJson;
    });
};

export default {
  fetchGlobalFeatureConfig
};
