import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PersistGate } from 'redux-persist/lib/integration/react';
import createStore from 'stores';
import { isNil } from 'ramda';
import useSecret from 'hooks/useSecret';
import Routes from './Routes';

const { store, persistor } = createStore();

const App = () => {
  const { i18n } = useTranslation(['navigation']);
  const { localePreferences } = useSelector(state => state.login);
  const updatedLocale = localStorage.getItem('current_locale');

  useSecret();

  const onChangeLanguage = () => {
    const sameLocale =
      localePreferences &&
      localePreferences.Value === updatedLocale &&
      updatedLocale;
    // for reload
    if (!isNil(updatedLocale) && !sameLocale) {
      i18n.changeLanguage(updatedLocale);
      // for display lang on login
    } else if (!isNil(localePreferences) && localePreferences.Value !== '') {
      i18n.changeLanguage(localePreferences.Value);
    } else {
      i18n.changeLanguage('en');
    }
  };

  useEffect(() => {
    onChangeLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localePreferences]);

  // expose store when run in Cypress
  if (window.Cypress) {
    window.store = store;
  }

  return (
    <PersistGate loading={null} persistor={persistor}>
      <Routes />
    </PersistGate>
  );
};

export default App;
