import { call, put } from 'redux-saga/effects';
import loginApi from 'services/login';
import { refreshTokenApi } from 'services/refreshToken';
import getUserDefaultLevel from 'services/user';
import { isNil } from 'ramda';
import history from 'helpers/history';
import {
  loginFailed,
  loginSuccess,
  getGlobalFeaturesSuccess
} from 'stores/login/actions';
import jwt_decode from 'jwt-decode';
import GlobalFeaturesService from 'services/globalFeatures';

/**
 * The saga for refreshing token, to implement on startup saga
 */
export function* refreshToken() {
  const data = yield call(refreshTokenApi);
  // Server API can only have string instead of boolean for IsAdmin
  // so we convert to boolean here.
  // TODO: Check with Chat on missing property in new identity service
  const isTrialExpired =
    String(data.UserAccountExpired).toLowerCase() === 'true';
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      // if the API return error message
      yield put(loginFailed(data.error));
    } else {
      // save access_token & refresh_token on localStorage, in future maybe put in secure storage?
      localStorage.setItem('access_token', data.access_token ?? '');
      // Api does not return refresh token in response
      if (!isNil(data.refresh_token)) {
        localStorage.setItem('refresh_token', data.refresh_token);
      }
      const decoded = jwt_decode(data.access_token);
      let { IsAdmin } = decoded;
      IsAdmin = String(IsAdmin).toLowerCase() === 'true';
      const {
        mySecret,
        myLocale,
        user_name: userName,
        user_id: userID,
        role
      } = decoded;
      // if the user data is successfully fetched
      try {
        const user = yield call(getUserDefaultLevel);
        if (!isNil(user)) {
          if (isNil(user.error)) {
            yield put(
              loginSuccess(
                user,
                IsAdmin,
                mySecret,
                isTrialExpired,
                myLocale,
                data.UserPreferences
              )
            );
          }
        }
      } catch (e) {
        const user = {
          UserId: userID,
          UserName: userName,
          Role: role
        };
        yield put(
          loginSuccess(
            user,
            IsAdmin,
            mySecret,
            isTrialExpired,
            myLocale,
            data.UserPreferences
          )
        );
      }
    }
  }
}

/**
 * The saga for logout, clearing tokens, redirect to login screen
 */
export function* logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('current_locale');
  const loginRoute =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NETLIFY === 'true'
      ? '/login'
      : '/members-login';

  const hasFlutter = window.flutter_inappwebview;
  if (hasFlutter) hasFlutter.callHandler('logOutFunc');
  yield call([history, history.push], loginRoute);
}

/**
 * The saga for login by username and password
 */
export default function* login({ username, password }) {
  const data = yield call(loginApi, username, password);
  // Server API can only have string instead of boolean for IsAdmin
  // so we convert to boolean here.
  // TODO: Check with Chat on missing property in new identity service
  const isTrialExpired =
    String(data.UserAccountExpired).toLowerCase() === 'true';
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      // if the API return error message
      yield put(loginFailed(data.error));
    } else {
      // save access_token & refresh_token on localStorage, in future maybe put in secure storage?
      localStorage.setItem('access_token', data.access_token ?? '');
      localStorage.setItem('refresh_token', data.refresh_token ?? '');
      const decoded = jwt_decode(data.access_token);
      let { IsAdmin } = decoded;
      IsAdmin = String(IsAdmin).toLowerCase() === 'true';
      const {
        mySecret,
        myLocale,
        user_name: userName,
        user_id: userID,
        role
      } = decoded;
      // if the user data is successfully fetched
      try {
        const user = yield call(getUserDefaultLevel);
        if (!isNil(user)) {
          if (isNil(user.error)) {
            yield put(
              loginSuccess(
                user,
                IsAdmin,
                mySecret,
                isTrialExpired,
                myLocale,
                data.UserPreferences
              )
            );
            yield call([history, history.push], '/dashboard');
          } else if (user.error === 'account expired') {
            yield put(loginFailed(user.error));
          }
        }
      } catch (e) {
        const user = {
          UserId: userID,
          UserName: userName,
          Role: role
        };
        yield put(
          loginSuccess(
            user,
            IsAdmin,
            mySecret,
            isTrialExpired,
            myLocale,
            data.UserPreferences
          )
        );
        yield call([history, history.push], '/dashboard');
      }
    }
  }
}

// Saga for fetching global config json
export function* fetchGlobalFeatureConfig() {
  const data = yield call(GlobalFeaturesService.fetchGlobalFeatureConfig);
  if (!isNil(data)) {
    yield put(getGlobalFeaturesSuccess(data));
  }
}
