import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_RESET,
  REFRESH_TOKEN_REQUEST,
  SET_SHOW_TRIAL_POPUP,
  SET_USER_DATA,
  GET_GLOBAL_FEATURES_REQUEST,
  GET_GLOBAL_FEATURES_SUCCESS
} from './actionTypes';

export const loginRequest = (username, password) => {
  return { type: LOGIN_REQUEST, username, password };
};

export const loginSuccess = (
  user,
  isAdmin,
  mySecret,
  isTrialExpired,
  myLocale,
  userPreferences
) => {
  return {
    type: LOGIN_SUCCESS,
    user,
    isAdmin,
    mySecret,
    isTrialExpired,
    myLocale,
    userPreferences
  };
};

export const loginFailed = error => {
  return { type: LOGIN_FAILED, error };
};

export const logout = () => {
  return { type: LOGIN_RESET };
};

export const refreshTokenRequest = () => {
  return { type: REFRESH_TOKEN_REQUEST };
};

export const setUserData = user => {
  return { type: SET_USER_DATA, user };
};

export const setShowTrialPopup = show => {
  return {
    type: SET_SHOW_TRIAL_POPUP,
    show
  };
};

export const getGlobalFeaturesRequest = () => {
  return {
    type: GET_GLOBAL_FEATURES_REQUEST
  };
};

export const getGlobalFeaturesSuccess = data => {
  return {
    type: GET_GLOBAL_FEATURES_SUCCESS,
    data
  };
};
