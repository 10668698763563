import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteWorksheet } from 'stores/worksheet/actions';
import './SavedWorksheetRowSubMenu.styles.scss';
import ConfirmModal from 'components/Alert/ConfirmModal';
import { featureToggle } from 'constants/index';
import { isNil } from 'ramda';

export default function SavedWorksheetRowSubMenu(props) {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();
  const { worksheet } = props;

  const handleDelete = selectedWorksheet => {
    dispatch(deleteWorksheet(selectedWorksheet.ID));
  };

  const handlePrint = async () => {
    const sessionSecret = sessionStorage.getItem('secret');
    const url = isNil(sessionSecret)
      ? `/worksheet/print/${worksheet.ID}`
      : `/worksheet/print/${worksheet.ID}?secret=${sessionSecret}`;
    const win = window.open(url, '_blank');
    win.focus();
  };

  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <ConfirmModal
        isShow={showConfirm}
        onConfirm={() => handleDelete(worksheet)}
        onClose={() => setShowConfirm(false)}
        title={t(`create-assign-saved:delWo.delWo`, 'Delete Worksheet')}
      >
        <p>
          {t(
            `create-assign-saved:delWo.delConfirm`,
            'This assignment will be deleted'
          )}
        </p>
        <p>
          {t(`create-assign-saved:delWo.proceed`, 'Do you want to proceed?')}
        </p>
      </ConfirmModal>
      <Dropdown className="saved-worksheet__dropdown">
        <Dropdown.Toggle id={`dropdown-saved-worksheet-${worksheet.ID}`} />
        <Dropdown.Menu>
          {featureToggle.printButton && (
            <Dropdown.Item
              className="saved-worksheet__action saved-worksheet__action--print"
              eventKey="2"
              onClick={() => handlePrint()}
            >
              <i className="icon icon-print" />
              {t(`create-assign-saved:main.print`, 'Print')}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            className="saved-worksheet__action saved-worksheet__action--delete"
            eventKey="4"
            onClick={() => setShowConfirm(true)}
          >
            <i className="icon icon-trash" />
            {t(`create-assign-saved:main.delete`, 'Delete')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
