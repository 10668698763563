import { useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

// For handling users who access Student Portal using secret link generated by Contingency Admin
const useSecret = () => {
  const query = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  const { secret } = query;
  const { globalFeatureConfig } = useSelector(state => state.login);
  const isSecretLinkEnabled = isNil(globalFeatureConfig)
    ? false
    : globalFeatureConfig.secretlink?.parameters?.IsEnabled === true;
  const [contingencySecret, setContingencySecret] = useState(null);
  useEffect(() => {
    if (!isNil(secret)) {
      setContingencySecret(secret);
      sessionStorage.setItem('secret', secret);
    } else if (contingencySecret === null) {
      const sessionSecret = sessionStorage.getItem('secret');
      if (isNil(sessionSecret)) {
        setContingencySecret('');
      } else {
        setContingencySecret(sessionSecret);
      }
    }
  }, [contingencySecret, secret]);
  useEffect(() => {
    if (
      !isNil(contingencySecret) &&
      isSecretLinkEnabled &&
      globalFeatureConfig.secretlink?.parameters?.key !== contingencySecret &&
      window.location.pathname !== '/login'
    ) {
      window.location.href =
        globalFeatureConfig.secretlink?.parameters?.supportUrl ??
        'https://support.koobits.com/hc/en-gb';
    }
  }, [contingencySecret, globalFeatureConfig, isSecretLinkEnabled]);
};

export default useSecret;
