import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import HeaderFull from 'components/CreateAndAssign/HeaderFull';
import { useSelector, useDispatch } from 'react-redux';
import CircleType from 'components/CreateAndAssign/CircleType';
import Border from 'components/Shared/Border';
import DateTimeRangePicker from 'components/CreateAndAssign/DateTimeRangePicker';
import { assignmentTypes } from 'constants/index';
import DateTimePicker from 'components/CreateAndAssign/DateTimePicker';
import { nextStep } from 'stores/utils/step/actions';
import {
  setStartEndDate,
  setReleaseDate,
  setKokoCredit
} from 'stores/worksheet/assign/actions';
import { Spinner } from 'react-bootstrap';
import history from 'helpers/history';

export default function AssignTime() {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();
  const { selectedWorksheet, isLoading } = useSelector(
    state => state.worksheet
  );

  const { startDate, endDate, releaseDate, kokoCredit } = useSelector(
    state => state.assign
  );

  // must fullfil by data selected worksheet but I can find that
  // kokoCredits 10, 15, 20 is temporarily removed
  const kokoCredits = [5];
  const [kokoCreditVal, setKokoCreditVal] = useState(kokoCredit);
  const startDateVal = moment(startDate, 'YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ');
  const endDateVal = moment(endDate, 'YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ');

  const releaseDateVal = moment(releaseDate, 'YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ');

  const applyCallback = (startDateValue, endDateValue) => {
    dispatch(
      setStartEndDate(
        startDateValue.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
        endDateValue.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ')
      )
    );
  };

  const applyCallbackRelease = (e, picker) => {
    dispatch(
      setReleaseDate(picker.startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'))
    );
  };

  const onChangeKoko = event => {
    setKokoCreditVal(event.target.value);
    dispatch(setKokoCredit(event.target.value));
  };

  const handleNext = () => {
    if (!kokoCredit) {
      dispatch(setKokoCredit(kokoCreditVal));
    }

    if (!startDate && !endDate) {
      dispatch(
        setStartEndDate(
          startDateVal.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
          endDateVal.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ')
        )
      );
    }

    if (
      selectedWorksheet.PresentationType.AssignmentTypeID ===
        assignmentTypes.test &&
      !releaseDate
    ) {
      dispatch(
        setReleaseDate(releaseDateVal.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'))
      );
    }

    dispatch(nextStep());
  };

  const handleCancel = () => {
    history.push('/create-and-assign/saved-worksheet');
  };

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Spinner animation="border">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <div className="container schedule-content">
      <HeaderFull>
        <div className="d-flex flex-row align-items-center justify-content-center schedule-content-title">
          <CircleType
            typeId={selectedWorksheet?.PresentationType.AssignmentTypeID}
          />
          <h1>{selectedWorksheet?.Title}</h1>
        </div>
        <div className="schedule-content-nav-btn">
          <button
            onClick={handleCancel}
            type="button"
            className="btn btn-outline-primary"
          >
            {t(`create-assign-saved:scheduleWo.cancel`, 'Cancel')}
          </button>
          <button
            onClick={handleNext}
            type="button"
            className="btn btn-primary"
          >
            {t(`create-assign-saved:scheduleWo.next`, 'Next')}
          </button>
        </div>
      </HeaderFull>
      <Border />
      <div>
        <div className="d-flex flex-row">
          <div>
            <div className="save-assign__content-action-item">
              <i className="save-assign__content-action-icon icon-class-start-orange" />
              <div className="save-assign__content-action-caption">
                <p>
                  {t(`create-assign-saved:scheduleWo.startend`, 'Start / End')}
                </p>
                <DateTimeRangePicker
                  startDate={startDateVal}
                  endDate={endDateVal}
                  applyCallback={applyCallback}
                />
              </div>
            </div>
            {selectedWorksheet?.PresentationType.AssignmentTypeID ===
              assignmentTypes.test && (
              <div className="save-assign__content-action-item">
                <div
                  className="save-assign__content-action-caption"
                  style={{ marginLeft: 35 }}
                >
                  <p>
                    {t(
                      `create-assign-saved:scheduleWo.releaseDate`,
                      'Release Date'
                    )}
                  </p>
                  <DateTimePicker
                    selectedDate={releaseDateVal}
                    applyCallback={applyCallbackRelease}
                    minDateVal={endDateVal}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="save-assign__content-action-item">
        <i className="icon-class-reward-orange save-assign__content-action-icon" />
        <div className="save-assign__content-action-caption">
          <p>{t(`create-assign-saved:scheduleWo.rewards`, 'Rewards')}</p>
          <div className="save-assign__content-action-rewards">
            <div className="schedule-option-arrow">
              <select
                onBlur={onChangeKoko}
                onChange={onChangeKoko}
                defaultValue={kokoCreditVal}
                name="kokoCredit"
                className="schedule-option-rewards"
              >
                {kokoCredits.map(kokoItem => {
                  return (
                    <option value={kokoItem} key={kokoItem}>
                      {kokoItem}
                    </option>
                  );
                })}
              </select>
            </div>
            <p>Koko</p>
          </div>
        </div>
      </div>
    </div>
  );
}
