import styled from 'styled-components';

export const TopicalWorksheetContainer = styled.div`
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 20px;
  height: calc(100vh - 75px);
  margin-left: 200px;
  background-color: #f2f2f2;

  .topical-worksheet-header {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-left: 10px;
      font-size: 20px;
      color: #545454;
    }
  }
  .topical-loader {
    position: absolute;
    top: 50%;
    left: 55%;
  }
  .topical-worksheet-dropdown {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    .tw-levels {
      display: flex;
      align-items: center;
      .dropdown-menu.show {
        max-height: 400px;
        overflow: auto;
        background: #fff;
      }
      button {
        padding: 3px 10px;
        font-size: 15px;
        border: 1px solid #c5c5c5 !important;
      }
    }
    span {
      font-size: 14px;
      color: #727272;
      margin-right: 40px;
    }
  }
  .topical-worksheet-body {
    margin-top: 50px;
    .topical-worksheet-card {
      display: flex;
      background: #ffffff;
      margin-bottom: 30px;
      border-radius: 5px;
      user-select: none;
      .topical-name-category {
        flex: 1;
        border-right: 0.5px solid #c5c5c5;
        display: flex;
        padding: 10px;
        align-items: center;
        .syllabus-img {
          width: 70px;
        }
        .syllabus-name {
          margin-left: 5px;
          .syllabus-item-name {
            font-size: 16px;
            font-weight: bold;
            color: #1d1d1d;
          }
        }
      }
      .topical-difficulty-category {
        flex: 3;
        border-right: 0.5px solid #c5c5c5;
        display: flex;
        padding: 10px;
        justify-content: space-evenly;
        align-items: center;
      }
      .topical-total-category {
        flex: 1;
        padding: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        .tw-category-total {
          span {
            font-size: 18px;
            color: #1d1d1d;
            font-weight: 700;
            margin-left: 10px;
          }
        }
        .tw-category-controls {
          display: flex;
          width: 90%;
          justify-content: space-evenly;
          .tw-push-cart {
            background: #ffffff;
            border: 1px solid #ff5b00;
            border-radius: 4px;
            color: #ff5b00;
            padding: 5px 10px;
            font-size: 18px;
          }
          .tw-create {
            background: #ff5b00;
            border: 1px solid #cf4a00;
            border-radius: 4px;
            color: #fff;
            font-size: 18px;
            padding: 5px 20px;
          }
          .tw-create:disabled,
          .tw-push-cart:disabled {
            cursor: not-allowed !important;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    margin-left: 10px;
  }
`;

export const NoSyllabusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-syll-img {
  }
  .title {
    color: #555555;
    font-weight: 900;
    font-size: 18px;
    margin: 25px 0 12px;
  }
  .suggestion {
    color: #555555;
    font-size: 18px;
  }
`;
